export default class ApiCargaService {
  constructor(http) {
    this._http = http
  }

  async salvar(autorizacaoPortabilidade) {
    const { data } = await this._http.post(
      `/api/autorizacaoConsignataria/liberarAcesso`,
      autorizacaoPortabilidade,
    )
    return data
  }

  async cancelarAcesso(autorizacaoPortabilidade) {
    return await this._http.put(
      `/api/autorizacaoConsignataria/cancelarAcesso`,
      autorizacaoPortabilidade,
    )
  }

  async listarConsignatariasComAutorizacao(servidorId) {
    const { data } = await this._http.get(
      `/api/autorizacaoConsignataria/${servidorId}`,
    )
    return data
  }

  async consignatariaEstaAutorizada(servidorId, consignatariaId) {
    const { data } = await this._http.get(
      `/api/autorizacaoConsignataria/${servidorId}/consignataria/${consignatariaId}`,
    )
    return data
  }
}
